// Common scss file for import all stylesheet modules

@import './styles/fonts/all';

@import './styles/helpers/all';
@import './styles/objects/all';

// Need to check appropriate place for this
.cdk-overlay-container {
    z-index: 100099 !important;
  }


  .error-dialog {
    padding: 1em;
    .header-text {
      color: #47525e;
      font-size: 1em;
      font-weight: 700;
      text-align: center;
    }

    .normal-text {
      color: #47525e;
      font-size: 0.75em;
      font-weight: 400;
      word-break: break-word;
      -ms-word-break: break-all;
      text-align: center;
    }
     // Edge support
     @supports (-ms-ime-align:auto) {
      .normal-text {
      word-break: break-all;
      }
    }
    .confirmation-button {
      border-radius: 8px;
      color: #ffffff;
      font-size: 1em;
      font-weight: 700;
      text-align: center;
      background: #cc0000;
      border: none;
      padding: 8px 10px;
      width: 89px;
      cursor: pointer;
    }

    .image-div {
      text-align: center;
    }

    .button-div {
      text-align: center;
      margin-top: 1.5em;
    }
  }

  *:focus:not(.focus-visible) {
    outline: none;
  }  
  .focus-visible:focus {
    outline: none;
    -webkit-box-shadow: 0px 0px 3px 2px rgba(64,139,255,0.66); 
    box-shadow: 0px 0px 3px 2px rgba(64,139,255,0.66);
  }