@import '../../app.scss';

app-master-page {

  .no-reading-text {
    text-align: center;
    padding-top: 50px;
    color: #cc0000;
    font-weight: bold;
  }
  .loading-view-dashbord {
    @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
    background: $white1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 20;
    }

  .page-background {
    width: 100%;
    background-color: $gray2;
    //height: calc(100vh - 70px);
    height: calc(100vh - 60px);
    position: relative;
    overflow: hidden;
    &.scroll {
      overflow-y: scroll;
    }

    .loading-view-measurement {
      position: absolute;
      top: 5px;
      right: 15px;
      bottom: 0;
      left: 15px;
      background: $white1;
      z-index: 2;
      display: block;
      border-radius: 10px;
    }
    .loading-view-schamatic-measurement {
      position: absolute;
      top: 5px;
      right: 0px;
      bottom: 0;
      left: 0px;
      background: #ffffff;
      z-index: 2;
      display: block;
      border-radius: 10px;
    }
    .custom-button-danger {
      color: #ffffff;
      font-family: UniversLTStd, sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: normal;
      padding: 8px 10px;
      border-radius: 8px;
      background-color: #cc0000;
      cursor: pointer;
      border: 1px solid #cc0000;
    }
    .main-section {
      box-sizing: border-box;
      width: 100%;
      display: grid;
      grid-template: 1fr / 2fr 1fr;
      gap: 40px;
      padding: 40px;
      padding-left: 90px;

      &::-webkit-scrollbar {
        display: 3px;
      }
    }

    .export-section {
      padding: 20px;
      padding-left: 90px;
    }

    .map-section {
      display: block;
      // background-color: red;
      width: 100vw; 
      height: 100vh;
      margin-left: 60px;
      margin-top: 10px;
      margin-right: 10px;
    }

    .chart-section {
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      border-radius: 8px;
      max-height: calc(100vh - 125px);
      height: 100%;
      background-color: $white1;
      box-shadow: 0 2px 50px 0 rgba($black2, 0.3);
    }

    .show {
      display: contents;
    }

    .hide {
      display: none;
    }

    .historicalview {
      grid-template: 1fr / 2fr;
    }

    .no-sensor-selected-display {
      text-align: center;
      vertical-align: middle;
      line-height: calc(100vh - 60px - 80px);
      height: calc(100vh - 60px - 80px);

      .edit-rule-btn {
        background: $red1;
        border-radius: 8px;
        border: 1px solid $red1;
        padding: 8px 10px;
        text-transform: uppercase;
      }

      .select-button {
        bottom: 40%;
        left: 45%;
        position: absolute;
      }
    }

    .scroll-bar {
      overflow-y: auto;
    }

    .measurement-section {
      display: flex;
      flex-direction: column;
      position: relative;
      border-radius: 8px;
      background-color: $white1;
      height: calc(100vh - 125px);
      //height:100%;
      box-shadow: 0 2px 50px 0 rgba($black2, 0.3);

      &::-webkit-scrollbar {
        display: 3px;
      }

      .measurement-header-container {
        border-bottom: 1px solid $gray4;
      }

      .measurement-header {
        @include black-label2;
        padding: 24px 0 24px 24px;
      }
    }

    .alert-panel {
      right: 0;
      top: 0px;
    }
    .ui-toast-message-content {
      background: $red3;
    }
    .ui-toast-close-icon {
      color: $black1;
      text-decoration: none;
    }

    ng-deep {
      p-toastitem {
        .ui-toast-message {
          background-color: $white1;

          .ui-toast-close-icon {
            color: $black1;
            text-decoration: none;
          }

          .ui-toast-icon {
            display: none;
          }

          .ui-toast-message-text-content {
            margin-left:0;
          }

          &.ui-toast-message-error {
            background-color: $red3;
          }
        }

        .ui-toast-message-warn {
          background-color: $red3;

          .login-bck-btn {
            text-align: center;
          }

          p, h3 {
            word-break: break-all;
            word-break: break-word;
          }

          button {
            @include secondary-button;
            border: solid 1px $red1;
            background: transparent;
            opacity: 1;
            padding: 8px;
          }

          .ui-toast-close-icon {
            display: none;
          }
        }
      }
    }

    .export-card {
      padding: 40px;
    }
    .font-style {
      font-size: 3em;
    }
    .text-style-center {
      text-align: center;
    }
    @media (max-width: 767px) {
      height:auto;
      .main-section {
       display: inline-block;
       padding:20px  10px;
     }
      .sm-height {
        max-height: 100% !important;
     }

     .hide {
      display: none;
    }

     .measurement-section {
       top: 1%;
       height: 100vh;
     }
     .padding-top-2 {
      padding-top: 2%;
    }
     .ui-dialog {
      left: 0px !important;
      min-width: 100% !important;
      width: 100% !important;
     }
     .modal-body {
      width:100% !important;
     }
    .export-section {
      padding:10px !important;
    }
    .move-left {
      margin-left: 0px !important;
    }
   }

   @media screen and ( -ms-high-contrast:active) ,screen and (-ms-high-contrast:none){
    .chart-section {
      min-height: calc(100vh - 125px);
      width: 100%;
      position: static;
    }
  }
  @media (max-width: 768px) and (min-width: 767px) {
    .measurement-section
    {
      margin-right: -8% !important;
    }
  }
  @media (max-width: 575px) and (min-width: 360px) {
    height:auto;
   .ui-dialog {
    top: 40px !important;
   }
   .measurement-section {
    height: 100vh;
  }
  }

  @media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : portrait) {
  .measurement-section {
    height: 100vh !important;
  }
  .map-section{
    margin-left: 20px;
  }
}


  @media (max-height: 414px) {
  .ui-dialog
  {
    top: 0px !important;
  }

}
@media (max-width: 359px) {
  .ui-dialog
  {
    top: 0px !important;
    zoom: 90%;
    top: -26px;
  }
  .map-section{
    margin-left: 20px;
  }
}

}

}
