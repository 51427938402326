// Mixin styles for usage
@import "font-colors";

@mixin common-font(
  $colorValue,
  $fontFamily,
  $fontSize,
  $fontWeight,
  $fontStretch,
  $fontStyle,
  $letterSpacing
) {
  color: $colorValue;
  font-family: $fontFamily, sans-serif;
  font-size: $fontSize;
  font-weight: $fontWeight;
  font-stretch: $fontStretch;
  font-style: $fontStyle;
  letter-spacing: $letterSpacing;
}

@mixin secondary-button {
  @include common-font($red1, UniversLTStd, 14px, bold, normal, normal, normal);
  line-height: normal;
  text-align: center;
}

@mixin primary-button {
  @include common-font(
    $white1,
    UniversLTStd,
    14px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: center;
}

@mixin secondary-button1 {
  @include common-font(
    $gray1,
    UniversLTStd,
    14px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: center;
}

@mixin black-label {
  @include common-font(
    $black1,
    UniversLTStd,
    14px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin black-label-small {
  @include common-font(
    $black1,
    UniversLTStd,
    12px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: center;
}

@mixin black-label-bold {
  @include common-font(
    $black1,
    UniversLTStd,
    14px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin black-label1 {
  @include common-font(
    $black1,
    UniversLTStd,
    24px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin black-label-right {
  @include common-font(
    $black1,
    UniversLTStd,
    18px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: right;
}

@mixin black-label-center {
  @include common-font(
    $black1,
    UniversLTStd,
    18px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: center;
}

@mixin black-label2 {
  @include common-font($black1, UniversLTStd, 18px, bold, normal, normal, 1px);
  line-height: normal;
}

@mixin black-label3 {
  @include common-font(
    $black1,
    UniversLTStd,
    36px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin black-label4 {
  @include common-font(
    $black1,
    UniversLTStd,
    14px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: right;
}

@mixin gray-label1 {
  @include common-font(
    $gray1,
    UniversLTStd,
    15px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin gray-label2 {
  @include common-font(
    $gray1,
    UniversLTStd,
    18px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin gray-label-right {
  @include common-font(
    $gray1,
    UniversLTStd,
    18px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
  text-align: right;
}

@mixin gray-label3 {
  @include common-font(
    $gray1,
    UniversLTStd,
    24px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin gray-label4 {
  @include common-font(
    $gray1,
    UniversLTStd,
    12px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin gray-label5 {
  @include common-font(
    $gray4,
    UniversLTStd,
    14px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin white-label {
  @include common-font(
    $white1,
    UniversLTStd,
    14px,
    900,
    normal,
    normal,
    normal
  );
  line-height: 1.21;
}

@mixin black-small-label {
  @include common-font(
    $black1,
    UniversLTStd,
    12px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin red-lable-large {
  @include common-font(
    $red1,
    UniversLTStd,
    20px,
    bold,
    normal,
    normal,
    normal
  );
  line-height: normal;
}

@mixin red-label-small {
  @include common-font(
    $red1,
    UniversLTStd,
    14px,
    normal,
    normal,
    normal,
    normal
  );
  line-height: normal;
}
