@import '../../app.scss';
@import '../../containers/export/export.component.scss';

app-export-history {
    .export-history-container {
        height: 80vh;
        margin-top: 0;
        box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.3);
        background-color: $white1;
        border-radius: 8px;
        z-index: 2;
        margin-left: 20px;
        display: inline-block;
        vertical-align: top;
        padding: 15px;
        position: relative;
        overflow: hidden;
      }

    .empty-exports-view {
        position: absolute;
        top:50%;
        width: 90%;
        margin: auto;
        text-align: center;
    }

    .export-list-container {
        padding: 15px;
        max-height: 70vh; 
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .exports-list {
        list-style-type: none;
        width: 100%;
        padding: 0;
        margin:0;
        height:50%;
        max-height:50%;
        overflow: auto;
    }

    li {
        border-bottom: solid 1px $gray4;
        padding: 1px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 30px;
    }

    .export-file-name {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .export-action-buttons-container {
        height: 100%;
        display: flex;
        align-items: center;
    }

    .export-action-button {
        cursor: pointer;
        border-left: solid 1px $gray4;
        padding: 5px 10px;
    }

    .export-state-container {
        height: 100%;
        padding: 5px 2px;
    }

    .export-status {
        display: table-cell;
        vertical-align: middle;
        margin-left: 0 !important;
    }

    .export-status-failed {
        @extend .export-status;
        text-decoration: underline;
        font-weight: bold;
        font-size: 14px;
        color: $red1;
    }

    .progress-bar {
        width: 75px;
        height: 100%;
        border: solid 2px black;
        background-color: white;
    }
      
    .progress-bar-fill {
        background-color: #6fd404;
        height: 100%;
    }

    @media (min-width: 1610px) {
        .export-history-container {
            max-width: 340px;
        }
    }

    @media (max-width:1609px) {
        .export-history-container {
            margin-left: 0px;
            margin-top: 20px;
            min-width: calc(100vw - 500px) !important;
            overflow: hidden;
        }
    }
    
    @media (max-width:767px) {
        .export-history-container {
            margin: 0px 30px 20px 30px;
            min-width: calc(100vw - 80px) !important;
        }
    }

    @media (max-width:575px) {
        .export-history-container {
            margin: 20px 0 0 0 !important;
        }
    }
}