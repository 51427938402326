@import '../../styles/fonts/all';

app-terms-conditions {
  .backdrop {
    background-color: rgba(19, 21, 43, 0.75);
    height: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5000;
  }

  .modal-body {
    background-color: #ffffff;
    border-radius: 8px;
    left: 50%;
    font-size: smaller;
    max-height: 100%;
    overflow-y: auto;
    position: fixed;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 999px;
  }
  .header {
    height: auto;
    padding-top: 27px;
  }
  .title {
    @include black-label-bold;
    font-size: 18px;
    margin-bottom: 20px;
  }
  button {
    border-radius: 8px;
    border: 1px solid $red1;
    box-shadow: unset;
    cursor: pointer;
    height: 32px;
    width: 108px;
    &.cancel {
      @include secondary-button;
      background-color: unset;
    }
    &.done {
      @include primary-button;
      background-color: $red1;
      margin-left: 10px;
      &:disabled {
        cursor: default;
        background-color: $gray1;
        border: none;
        opacity: 0.5;
      }
      
    }
    &:hover {
      background-color:$red1;
      color: $white1;
    }
  }
  .footer {
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
