// Colors used in the app are defined here

// White variations
$white1: #ffffff;

// Black variations
$black1: #13152b;
$black2: #000000;

// Gray Variations
$gray1: #838a99;
$gray2: #f8f9fb;
$gray3: #979797;
$gray4: #d4d8df;
$gray5: #7e7f8a;
$gray6: #f4f9fd;
$gray7: #e9ebee;

$dark-gray: #4a4a4a;

// Red Variations
$red1: #cc0000;
$red2: #F9E5E5;
$red3: #f8b7bd;
