app-side-nav {

  .fa-lg {
    font-size: 3em;
    line-height: 2em;
    vertical-align: -15%;
    color:white;
  }

  .disabled {
    opacity: 0.6;
  }

  .isLocked {
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
  }

  .menu-img {
    padding: 10px;
    height: 80px;
    width: 60px !important;
    display: block;
  }

  .export-div {
    background-color: #404040;
    height: 23px;
    text-align: center;
    position: relative;
    bottom:10px
  }

  .map {
    background: url('/assets/images/Maps.png') no-repeat center;
  }

  .alerts {
    background: url('/assets/images/Alertrules.png') no-repeat center;
  }

  .history {
    background: url('/assets/images/History.png') no-repeat center;
  }

  .schematics {
    background: url('/assets/images/Well_Schematics.png') no-repeat center;
  }

  .pressure-temperature {
    background: url('/assets/images/Chart.png') no-repeat center;
  }

  .exports {
    background: url('/assets/images/Export.png') no-repeat center;
  }
  .nav-side-menu {
    overflow: auto;
    overflow-x: hidden;
    font-family: verdana, sans-serif;
    font-size: 12px;
    font-weight: 200;
    background-color: #cc0000;
    position: absolute;
    top: 0px;
    padding-top: 15px;
    width: 60px;
    height: 100%;
    color: #e1ffff;
    z-index: 100;
    zoom:85%;
  }
  .nav-side-menu .brand {
    line-height: 50px;
    display: block;
    text-align: center;
    font-size: 14px;
  }
  .nav-side-menu .toggle-btn {
    display: none;
  }
  .nav-side-menu ul,
  .nav-side-menu li {
    list-style: none;
    padding: 0px;
    margin: 0px;
    line-height: 60px;
    cursor: pointer;
  }
  .nav-side-menu ul :not(collapsed) .arrow:before,
  .nav-side-menu li :not(collapsed) .arrow:before {
    font-family: FontAwesome;
    content: "\f078";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
  }
  .nav-side-menu ul .active,
  .nav-side-menu li .active {
    background-color: #404040;
    .fa-lg {
      color: #cc0000;
    }
  }
  .nav-side-menu ul .sub-menu li.active,
  .nav-side-menu li .sub-menu li.active {
    color: #d19b3d;
  }
  .nav-side-menu ul .sub-menu li.active a,
  .nav-side-menu li .sub-menu li.active a {
    color: #d19b3d;
  }
  .nav-side-menu ul .sub-menu li,
  .nav-side-menu li .sub-menu li {
    background-color: #181c20;
    border: none;
    line-height: 28px;
    border-bottom: 1px solid #23282e;
    margin-left: 0px;
  }
  .nav-side-menu ul .sub-menu li:hover,
  .nav-side-menu li .sub-menu li:hover {
    background-color: #020203;
  }
  .nav-side-menu ul .sub-menu li:before,
  .nav-side-menu li .sub-menu li:before {
    font-family: FontAwesome;
    content: "\f105";
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
  }
  .nav-side-menu li {
    padding-left: 0px;
    border-left: none;
    border-bottom: none;

  }
  .nav-side-menu li a {
    text-decoration: none;
    color: #e1ffff;
  }
  .nav-side-menu li a i {
    padding-left: 10px;
    width: 20px;
    padding-right: 20px;
  }

  .nav-side-menu li:hover {
    border-left: 5px solid #232b2b;
    outline: none;
  }

  .nav-side-menu li:focus {
    border-left: 5px solid #232b2b;
    outline: none;
  }
  @media (max-width: 767px) {
    .nav-side-menu {
      position: relative;
      width: 100%;
      padding-top: 0px;
      height: auto;
    }
    .nav-side-menu .toggle-btn {
      display: block;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 22px;
      z-index: 10 !important;
      padding: 3px;
      background: #cc0000;
      background-color: #cc0000;
      font-size: 2em;
      color: #ffffff;
      width: 40px;
      text-align: center;
    }
    .brand {
      text-align: left !important;
      font-size: 22px;
      padding-left: 20px;
      line-height: 68px !important;
      background-color: #ffffff;
    }
    .display-flex {
      display: flex ;
    }
    .label-style {
      width: 50%;
      font-weight: bold;
      margin: auto;
      font-size: 1.5em;
      cursor: pointer;
    }
  }
  @media screen and ( -ms-high-contrast:active) ,screen and (-ms-high-contrast:none){
    .nav-side-menu {
      zoom:normal;
      overflow-x: hidden;
    }
  }

  @media (min-width: 767px) {
    .nav-side-menu .menu-list .menu-content {
      display: block;
    }
  }

  @media (max-width: 575px){
    .nav-side-menu {
      padding-top: 0px;
    }

  }
  body {
    margin: 0px;
    padding: 0px;
  }
 .downloading{
   height: 98%;
   width: 45%;
  //  border-radius: 2px;
 }

}
