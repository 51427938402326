@import '../../app.scss';
.mat-checkbox-checked .mat-checkbox-background
 {
background-color: #cc0000 !important;
}
.mat-checkbox-inner-container-no-side-margin
 {
margin-right: 8px !important;
height: 17px !important;
width: 17px !important;
}
.tooltip-style {
  display: flex !important;
  max-width: 40% !important;
 }
@media (max-width: 767px)  {
  .tooltip-style {
    left: 0px !important;
   }
}
app-login-page {

  #okta-login-container {
    margin: 0 auto;
    max-width: 450px;
    // border: 1px solid silver;
    padding: 0px 10px;
    //box-shadow: 5px 5px 5px 0 silver;

    .auth-container{
      margin: 0px;
      width: inherit;
      min-width: inherit;
      border: none;
      box-shadow: none !important;
    }
    .okta-sign-in-header{
      display: none;
    }
    .okta-form-title, .okta-form-label, .custom-checkbox, .auth-footer{
      font-family: 'UniversLTStd', sans-serif !important;
    }
    .button-primary{
      background-color: #cc0000 !important;
    background: linear-gradient(#cc0000 ,#cc0000 ) !important;
    border-color: #cc0000 #cc0000 #cc0000 !important;
    font-family: 'UniversLTStd', sans-serif !important;
    }
    .focused-input{
      border-color:  #ee9e9e !important;//#cc0000 !important;
      box-shadow: 0 0 4px #eb8b8b !important;
    }
    .link.help:focus{
      box-shadow: 0 0 8px #cc0000 ;
    }
  }

   #okta-sign-in .auth-content{
     padding: 0px 10px 10px 10px;
   }
   #okta-sign-in .okta-form-title {
    color: #cc0000;
  }

  #okta-login-container input[type=checkbox] {
    width: 25px;
  }

  .login-background {
    background-image: url('/assets/images/login_background.jpg');
    // background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

  .label-style {
    color: #7f7f7f;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .employee-label-style {
    color: black;
    font-size: 0.8rem;
    font-weight: bold;
    margin-left:15px;
    display:block;
    margin-top:10px;
  }

  .move-left {
    margin-left:15px
  }

  #T-and-c, #privacyPolicy {
    color: #cc0000;
    text-decoration: underline;
  }

  .login-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-button-danger {
    color: #ffffff;
    font-family: UniversLTStd, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    padding: 8px 10px;
    border-radius: 8px;
    background-color: #cc0000;
    cursor: pointer;
    border: 1px solid #cc0000;
  }

  .login-left-wrapper {
    height: 100%;
    background: url('/assets/master-page/Login-Background1200x900px.jpg') no-repeat;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
  }
  /* This need to move to global*/
  .form-control {
    border-radius: initial;
    border: 1px solid #7f7f7f;
    padding: 0;
  }
  .inline-flex{
    display: inline-flex;
  }

  label {
    margin-bottom: .4rem;
    line-height: 1rem;
    font-size: 0.9rem;
  }
  .vof-css-decorom {
    position: absolute;
    bottom: 14px;
    max-height: 100%;
    //left: 1%;
    span a {
      text-decoration: none !important;
      color: #212529 !important;
      border-bottom: none !important;
    }
    label {
      padding-top: 10px;
    }
    .img {
      padding: 10px;
      zoom: 60%;
      margin-left: 15px;
    }
  }

  .addln-css {
    font-size: 3em;
    align-content: center;
    padding: 0 10px;
  }
  .checkbox{
    label {
      margin-left: 0 !important;
      font-size: 0.8rem;
    }
    span {
      display: flex;
      width: 90%;
    }

  }
  .move-checkbox {
  margin-left:2em;
  margin-top: 5px;
  }
  p-checkbox {
    margin: 0px 4px 0 0;
  }

    .ui-state-default{
    border: 1px solid #7f7f7f ;
    background: #7f7f7f ;
  }
    .ui-chkbox-box.ui-state-focus{
    box-shadow: 0px 0px 5px #7f7f7f;
  }
   .ui-chkbox-box.ui-state-active{
    border: none ;
    background: #7f7f7f ;
  }
   .ui-chkbox-box.ui-state-focus.ui-state-active{
    background: #7f7f7f ;
  }
  .login-button {
    border: none;
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
    padding: 8px 10px;
    border-radius: 8px;
    background-color: $red1;
    color: #ffffff;
    &:hover, &:focus {
      background-color: $red1;
      color: $white1;
      cursor: pointer;
    }
    &:disabled{
    background-color: #bcbcbc;
    color: #ffffff;
    cursor: auto;
    }
  }
  /* This need to move to global*/

  .horizontalRuler{
   // color:#7f7f7f;
    border-top: 1px solid #7f7f7f;
    margin: 20px 15px;
  }

  .forgotPassword {
    //text-decoration: underline;
    border-bottom: 1px solid $red1;
    color: $red1;
    margin-left: 15px;
  }
  .forgotPassword:hover{
    text-decoration: none;
    color: $red1;
  }

 .forget-reset-password {
   font-size: 0.8rem;
   line-height: 14px;
   display: inline-grid;
}

  .image-phone{
    content: url('/assets/master-page/contact.png');
  }
  .img-height{
    height: 2.5em;
    padding: 0px 8px;
    display: inline-block;
  }
  .display-flex{
    display: flex;
  }
  .inline-block{
    display: inline-block;
  }
  .padding-28 {
    padding-top: 28px;
  }
  .form-div {
    //padding: 10px 0px;
    border: 1px solid #7f7f7f;
    height: calc(100vh - 152px);
    position: relative;
    min-height: 600px;
    min-width: 235px;
  }
  .login-header {
    color: #cc0000;
    font-size: 1em;
    font-weight: bold;
    margin: 15px 15px;
  }

  .form-group {
    padding: 0 15px;

    label {
      color: rgb(127, 127, 127);
      margin-left: 10px;
      font-weight: bold;
    }
  }

  p-menubar {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
  }

  .page-background {
    width: 100%;
    background-color: $white1;
    display: flex;
    height: 100%;
    min-height: 100vh;
    flex-direction: column;
    -ms-flex-direction: column;
    // flex: 1;
    // -ms-flex: auto;

    .content-div {
      flex: 1;
      -ms-flex: auto;
      bottom:0;
      margin: 10px 20px 9px 20px !important;
  }
// Only works on IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .content-div {
    min-height: 84.5vh;
  }
}
// For ipad
@media only screen and (min-width: 768px) and (max-height:1194px) and (orientation: portrait)
  {
    .login-button {
      padding: 8px 5px !important;
      margin-right: 5px !important;
    }
    .move-checkbox {
      margin-left: 1.5em !important;
    }
    .form-group {
      padding: 0 6px !important;
      label {
        margin-left: 5px !important;
      }
    }
    p-checkbox {
      margin: 0px !important;
    }
    .employee-label-style {
      margin-left:5px !important;
    }
    .horizontalRuler{
      margin: 20px 5px !important;
     }
   }
  .jumbotron {
    background-color: $white1;
    margin-top: 10%;
  }

  .footer-info {
    background-color: #D3D3D3;
    font-size: 0.8rem;
    bottom: 0;
    padding: 1.25rem 1.25rem;
    width: 100%;
    right: 0;
    left: 0;
    border: 1px solid #808080;

    .section-lower {
      color: black;
      margin-top: .5rem;

      a {
        font-size: 0.8rem;
        color: $red1;
        border-bottom: 1px solid $red1;
        //text-decoration: underline;
      }
      a:hover{
        text-decoration: none;
      }

      .font-weight-bold {
        font-size: 0.8rem;
        white-space: nowrap;
      }
    }
  }

  .configurations {
    position: absolute;
    width: 200px;
    right: 50px;
    background: $gray2;
    z-index: 10;

    .settings {
      @include black-label-center;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      width: 200px;
      height: 60px;
      background-color: $gray3;
    }

    .log-out {
      height: 30px;
      @include black-label-small;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
    }
  }

  .chart-section {
    contain: content;
    float: left;
    width: 65%;
    height: 80%;
    border-radius: 4px;
    background-color: $white1;
    box-shadow: 0 2px 50px 0 rgba($black2, 0.3);
    margin: 40px;
  }

  .measurement-section {
    position: relative;
    float: right;
    margin: 40px 40px 40px 0;
    width: 25%;
    height: 80%;
    border-radius: 4px;
    background-color: $white1;
    box-shadow: 0 2px 50px 0 rgba($black2, 0.3);
   // overflow: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .measurement-header {
      @include black-label2;
      padding: 24px 0 24px 24px;
    }
  }
  .vof-flex{
    display: flex;
  }

    p-menubar {
      .ui-menubar {
        background-color: $red1;
        }
      }

      .row {
        margin: 0px;
      }

    p-toastitem {
      .ui-toast-message {
        background-color: $white1;

        .ui-toast-close-icon {
          color: $black1;
          text-decoration: none;
        }

        .ui-toast-icon {
          display: none;
        }

        .ui-toast-message-text-content {
          margin-left:0;
        }

        &.ui-toast-message-error {
          background-color: $red3;
        }
      }

      .ui-toast-message-warn {
        background-color: $red3;

        .login-bck-btn {
          text-align: center;
        }

        p, h3 {
          word-break: break-word;
          word-break: break-all;
        }

        .ui-toast-close-icon {
          display: none;
        }
      }
    }
}

@mixin loading-spinner($activeColor: #cc0000, $selector: "&::before", $time: 1.5s) {
  @keyframes spinner {
      0% {
          transform: translate3d(-50%, -50%, 0) rotate(0deg);
      }

      100% {
          transform: translate3d(-50%, -50%, 0) rotate(360deg);
      }
  }


   @-ms-keyframes spinner { /*Internet Explorer*/
    0% {
      transform: translate3d(-50%, -50%, 0) rotate(0deg);
  }

  100% {
      transform: translate3d(-50%, -50%, 0) rotate(360deg);
  }
   }

  animation-play-state: running;
  opacity: 1;
  position: relative;

  &.-paused {
      animation-play-state: paused;
      opacity: 0.2;
      transition: opacity linear 0.1s;
  }

  #{$selector} {
      animation: $time linear infinite spinner;
      animation-play-state: inherit;
      border: solid 3px #7f7f7f;
      border-bottom-color: #{$activeColor};
      border-radius: 50%;
      content: "";
      height: 20px;
      left: 5%;
      opacity: inherit;
      position: absolute;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 20px;
      will-change: transform;
  }
}

.loading-spinner {
  @include loading-spinner;
  left: 10px;
  bottom: 5px;
  top: -8px!important;

}

.margin-line-height {
  line-height: 10px;
  margin:5px 0px;
}
.padding-10 {
  padding: 5px 16px;
}
.padding-5 {
  padding: 5px;
}
.margin-top-10 {
  margin-top:10px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ms-margin-left {
    margin-left: 0.5em;
  }
}

// Removed Title stretch on Edge
@supports (-ms-ime-align:auto) {
  @media (max-width:520px) {
  .ui-menubar{ .ui-menubar-custom { .app-header-left {
        .image-container {
          display: flex !important;
          .header-logo{
            padding-top: 0px !important;
        }  }  }  }
     }
    }
  }
}

app-login-page .form-div {
  min-height: 720px;
}

@media screen and (max-width:320px) {
  app-login-page .form-div {
    min-height: 800px;
}
}

@media screen and (min-width:321px) and (max-width:390px) {
  app-login-page .form-div {
    min-height: 720px;
  }
}

@media screen and (min-width:391px) and (max-width:567px) {
  app-login-page .form-div {
    min-height: 720px;
  }
}
@media screen and (min-width:600px) and (max-width:993px) {
  app-login-page .form-div {
    min-height: 800px;
  }
}

.terms {
  margin-top: 20px;
  margin-left: 10px;
}