.btn {
  @include button;

  &:hover {
    @include button-hover;
    cursor: pointer;
  }
}

.btn-secondary {
  @include button;
  background: $white1;
  color: $red1;
  &:hover {
    background-color: $red1;
    color: $white1;
    cursor: pointer;
  }
}
