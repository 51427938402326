@import '../../../app.scss';

app-charts-settings {
.charts-settings-modal {
  .display-flex {
    display: flex;
  }
  .custom-pt-2 {
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    justify-content: flex-end;
  }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    .ui-dialog {
      width: 60% !important;
      height: calc(70vh - 47px);
      top: 90px;
      overflow: hidden;
      max-height:625px;
      min-height:350px!important;

      &.ui-widget {
        z-index: 4000 !important;

        .ui-dialog-titlebar {
          padding: 0px !important;
          border-radius: 8px 8px 0 0;
        }

        .header-logo {
          background: $red1;
          padding: 9px;
          border-radius: 8px 0 0 0;
          position: relative;
          top: -1px;
          height:62px;

          .header-title{
            padding: 10px;
            color:white;
            display: block;
            line-height: normal;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
          }
          div {
            &:last-child {
              span {
                margin-left: 10px;
                max-width: 114px;
                }
              }
            }
          }

          .header-icon-close {
            float:right;
            color:white;
            cursor: pointer;
          }
        }

      .ui-dialog-content {
        max-height: none;
        height:auto!important;
       }

      .ui-dialog-titlebar-icon {
        display: none;
      }
    }


    .btn {
      width: 70px;
      outline: none;
      margin-bottom: 5px;
    }
    .ui-dialog-footer {
      border: none;
      padding: 0px;
    .btn-cancel,
    .btn-apply {
      width: 89px;
      margin: 5px;
      height: 32px;
      display: inline-block !important;
      &:focus {
        background-color: #cc0000;
        color: #fff
      }
    }
    .primary {
      @include primary-button;
      border-radius: 8px;
      width: 97px;
      background-color: $red1;
      height: 32px;
      border: solid 2px $red1;
      color: $white1;
      cursor: pointer;
      &:disabled {
        opacity: 0.5;
        background-color: $gray1;
        cursor: default;
        border: solid 2px $gray1;
      }
    }
    .width-auto {
      width:auto ;
    }
  }
    .pos-add-css {
      float: right;
      position: relative;
      right: 1em;
    }
    .header{
      border-radius: 8px;
      height: 32px;
      display: flex;
      align-items: center;
      background-color: #d4d8df;
      padding: 30px 25px;

      h3{
        margin-bottom: 0px;
      }
    }
    .footer{
      border-radius: 8px;
      height: auto;
      min-height: 70px;
      contain: content;
      align-items: center;
      background-color: #d4d8df;

    }

    .ui-listbox{
      width: 25em;
    }

    .measurement {
      &-data {
        @include black-label1;

        &-image {
          width: 20px;
          height: 20px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: solid 1px $gray1;
          position: relative;
          float: right;
          margin-right: 12px;
          .img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .charts-settings-modal-content {
      padding: 0px;
      overflow:hidden;

      .side-nav {
        background-color: #cc0000
      }
      .display-section {
        padding:15px;
      }
      .add-axis {
        display: flex;
      }

      .dropdown-content {
        font-size: 2em;
      }
      .header-button
      {
        width: auto;
      }

      .add-axis-text {
        font-size: 24px;
        opacity: 1 !important;
        display: flex;
        align-items: flex-start;
      }

      .ui-state-highlight{
        background-color: #ffffff;
        color: black;
      }

      .modal-content {
        height: calc(70vh - 320px);
        padding:25px;
        overflow-y: auto;
        max-height:412px;
        min-height:130px;

        .ui-chkbox-label {
          vertical-align: 0 !important;
          word-break: break-word;
          -ms-word-break: break-all;
          position: relative;
          top: -4px;
        }

        label.switch {
          text-align: left;
          width: 40px;
          height: calc(40px / 2);
          border-radius:60px;
          background-color: #cc0000;
          float: left;
          right: 10px;
          left:4px;
          position: relative;
          cursor: pointer;
        }

        label.switch > span {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 60px;
        }

        label.switch > input[type="checkbox"] {
          opacity: 0;
          position: absolute;
        }

        label.switch > span:before, label.switch > span:after {
          content: "";
          cursor: pointer;
          position: absolute;
        }

        label.switch > span:before {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          background-color: #f1f1f1;
          border-radius: 60px;
          transition: opacity .2s ease-out .1s, transform .2s ease-out .1s;
          transform: scale(1);
          opacity: 1;
        }

        label.switch > span:after{
          top: 50%;
          z-index: 3;
          transition: transform .4s cubic-bezier(0.44,-0.12, 0.07, 1.15);
          width: calc(40px / 2);
          height: calc(40px / 2);
          transform: translate3d(0, -50%, 0);
          background-color: #fff;
          border-radius: 100%;
          box-shadow: 0 2px 5px rgba(0, 0, 0, .3);
        }

        label.switch > input[type="checkbox"]:checked ~ span:before {
          transform: scale(0);
          opacity: .7;
        }

        label.switch > input[type="checkbox"]:checked ~ span:after {
          transform: translate3d(100%, -50%, 0);
        }

        .ui-dropdown-panel .ui-dropdown-item{
          &:hover{
            background-color: #cc0000;
            color: #ffffff;
          }
        }
        .measurement-data-image{
          cursor: pointer;
        }
        .ui-dropdown{
          border: 1px solid black;
        }
        .ui-chkbox-box.ui-state-active{
          background: black;
          border: 1px solid black;
        }
        .ui-chkbox-box.ui-state-focus{
          box-shadow: none;
        }
      }
      .label-adjustment .ui-chkbox-label {
        position: relative !important;
        top: 0px !important;
      }
    }
    .sensor-error-label {
      color:red;
    }

  }
  .ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
    background-color: $red1 !important;
  }
  .ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
   box-shadow: 0px 0px 5px $red1 !important;
  }

   @media only screen and (max-width: 640px)  {
    .modal-content {
      height: calc(70vh - 315px)!important;
}
}
@media only screen and (max-height: 455px)  and (orientation:landscape) {
  .ui-dialog {
    top:0px!important;
}
}
@media (max-height: 555px) and (max-width: 767px) and (min-width: 576px) and (orientation:landscape){
  .charts-settings-modal .ui-dialog {
    width: 90vw!important;
    min-width: unset!important;
    left: -46vw!important;
}
}
@media (max-width: 640px) and (orientation:landscape){
  .charts-settings-modal .ui-dialog {
    min-height:395px!important;
}
}
}
