@import '../../app.scss';
app-charts {

.data-chart {
  height: 100%;
   > div:nth-child(2) {
    white-space: pre-wrap !important;
    max-width: 60%;
    word-break: break-all;
    word-break: break-word;
  }
}

.disble-chart-setting {
  pointer-events: none;
}

.enable-chart-setting {
  pointer-events: unset;
}

.chart-export{
  margin-top: 20px;
}

.custom-button-danger {
  color: #ffffff;
  font-family: UniversLTStd, sans-serif;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  padding: 8px 10px;
  border-radius: 8px;
  background-color: #cc0000;
  cursor: pointer;
  border: 1px solid #cc0000;
}

.box-add-css {
  width:0.5em;
  height: 0.5em;
  display:inline-block;
  margin-left: 5px
  }

.chart-container {
  box-sizing: border-box;
  display: grid;
  height: 100%;
  padding: 1em 1.5em;
}
.no-data-sensor-link {
  cursor: pointer;
  color: #cc0000 !important;
  text-decoration: underline !important;
  font-size: 0.8em;
  font-weight: 700;
}

.chart-container.header {
  grid-template-rows: 1fr 10fr 1fr 1fr;
}
.chart-container.no-header {
  grid-template-rows: 0fr 10fr 1fr 1fr;
}
.chart-container.alert-modal {
  grid-template-rows: 0fr 10fr 0fr 0fr;
}

.chart-header {
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.icon-button-export {
  border-radius: 8px;
  border: solid 1px #838a99;
  background: $white1;
  cursor: pointer;
  margin-left: 10px;
  &:disabled {
    background-color: #bcbcbc;
  }
}

.settings-icon{
  margin-left: 8px;

  .icon-button-settings{
  border-radius: 8px;
  border: solid 1px #838a99;
  background: #ffffff;
  cursor: pointer;

  .img{
    width:24px;
    height:24px;
  }
 }
}
.selection-size-button-container {
  display: inline;
  align-items: center;
  border-radius: 8px;
  background-color: rgba(212, 216, 223, 0.25);
  padding: 10px;
  margin: 1rem 0 0 0;
}

.selection-size-button {
  cursor: pointer;
  @include secondary-button1;
  min-width: 5.5rem;
  height: 2rem;
  border: 1px solid $gray1;
  background-color: $white1;
  border-radius: 8px;
  margin-right: 1rem;
  &:focus{
    background-color: #cc0000;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    box-shadow:none;
    border: $red1;
  }
  &:hover{
    background-color: #cc0000;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    box-shadow:none;
    border: $red1;
  }
}
.selection-size-button-no-hover {
  cursor: pointer;
  @include secondary-button1;
  min-width: 5.5rem;
  height: 2rem;
  border: 1px solid $gray1;
  background-color: $white1;
  border-radius: 8px;
  margin-right: 1rem;
  &:focus{
    background-color: #cc0000;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    box-shadow:none;
    border: $red1;
  }
}
.selection-size-button-no-hover.active,
.selection-size-button.active,
.live.active {
  color: #ffffff;
  font-weight: bold;
  background-color: $red1;
  box-shadow: none;
  border: $red1;
  padding: 8px 10px;
}

.selection-size-button:last-child {
  margin-right: none;
}

.select-range-text {
  position: absolute;
  text-align: center;
  width: 10vw;
  top: calc(100% + 0.15rem);
  font-size: 70%;
}
mat-slider {
  width: 60%;
  margin-left: 20%;
}

    .dropdown-button {
      text-align: center;
      width: 10vw;
      top: calc(100% + 0.15rem);
      font-size: 70%;
      vertical-align: center;
      height: 2rem;
      padding: 1px;
      border-radius: 0.5rem;
      margin-right: 1rem;
      position: relative;
      .ui-multiselect {
        width: 120px;
        border-radius: 8px;
        height: 99%;
        border: 2px solid $gray1;
        .ui-multiselect-label-container {
          height: 100%;
        }
        .ui-multiselect-label {
          border-radius: 8px;
          @include black-label-bold;
          height: 100%;
          display: flex;
          align-items: center;
          text-align: center;
          padding-left: 21px;
        }
        .ui-multiselect-trigger {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
        }

      }

    }

  .sub-select-message {
    background-color: $white1;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .live {
    min-width: 90px;
    height: 2rem;
    border: 1px solid $gray1;
    background-color: $white1;
    border-radius: 0.5rem;
    margin-right: 1rem;
    color: $gray1;
  }
  .button-group {
    display: flex;
    justify-content: flex-end;
  }

  .selection-temperature {
    font-size: 1.8rem;
  }

  .display {
    @include secondary-button1;
    text-align: left;
    font-weight: bold;
  }

  .date {
    width: 100%;
    float: left;
    text-align: center;
    margin-top: 5px;
  }

  .chart-loading-view {
     @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
     width: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
     z-index: 2;
   }
   canvas {
    position: absolute;
    top: 0;
    left: 12px;
   }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
  }
  .loading-view {
      @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
      background: $white1;
      z-index: 6;
      display: block;
    }

   .loading-view-export {
      @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
      background: rgba(255, 255, 255, 0.6);
      z-index: 2;
      display: block;
    }

  .canvas-wrapper {
    position: relative;
    height: 100%;
  }

  .line-chart {
    display: flex;
    flex-direction: column;
  }

  .view-history-message {
    text-align: center;
    position: relative;
    top: 40px;
  }

  .live-data-off {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 300px;
    font-weight: bold;
    background-color: $gray4;
    border-radius: 8px;
    box-shadow: none;
  }

  .display-selector {
    min-width: 154px;
    border-radius: 150px;
    position: relative;
    //left: -8px;
    display: flex;
    flex-direction: row;

    .option-button {
      @include secondary-button1;
      height: auto;
      background-color: $white1;
      border: 1px solid $red1;
      font-size: 14px;
      width: 77px;
      cursor: pointer;
      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }
      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
        padding: 3px;
        width: 80px;
      }
      &.active {
        @include primary-button;
        background-color: $red1;
        box-shadow: none;
        position: relative;
      }
      &:focus {
        background-color: #cc0000;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        box-shadow: none;
      }
      &:hover {
        background-color: #cc0000;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        box-shadow: none;
      }
    }
    .option-button-no-hover {
      @include secondary-button1;
      height: auto;
      background-color: $white1;
      border: 1px solid $red1;
      font-size: 14px;
      width: 77px;
      cursor: pointer;
      &:first-child {
        border-radius: 0.5rem 0 0 0.5rem;
      }
      &:last-child {
        border-radius: 0 0.5rem 0.5rem 0;
        padding: 3px;
        width: 80px;
      }
      &.active {
        @include primary-button;
        background-color: $red1;
        box-shadow: none;
        position: relative;
      }
    }
  }

  .one-month-date-range {
    > .current-range {
      display: flex;
      align-items: center;
      padding-left:20px;
      span:not(:first-child){
        padding-left: 5px;
      }
    }
    .btn-duration {
      margin-left: 20px;
    }
    > p {
      @include black-label1;
      font-size: 16px;
    }
    .range-date {
      margin-right: 5px;
      margin-left: 5px;
      font-weight: bold;
    }
    .datepicker-hidden {
      display: none;
    }
  }
}
//For edge 13 and 14
@supports (-ms-ime-align: auto) {
.data-chart
{
  height: 60vh;
}
}
@media screen and ( -ms-high-contrast:active) ,screen and (-ms-high-contrast:none){
  .selection-size-button.active,
  .live.active {
  font-size:13px;
  }
  .selection-size-button-container
  {
   margin: 3rem 0 0 0;
  }

  .view-history-message
  {

  top:77vh;
  }
 .sub-select-message
  {
   height: 60vh;
  }

  .chart-container.header
  {
    grid-template-rows: 0fr 0fr 0fr 0fr;
    padding-top:7px;
  .selection-size-button-container
      {
       margin: 5rem 0 0 0;
      }
    }
  .chart-container.no-header
  {
    grid-template-rows: 0fr 10fr 1fr 1fr;
    padding-top:25px;
    .selection-size-button-container
    {
      margin: 2rem 0 0 0;
    }
  }
  .chart-loading-view
  {
    top: 29vh;
  }
}

@media screen and ( -ms-high-contrast:active) ,screen and (-ms-high-contrast:none)
{
  .data-chart {
    height:60vh;
    min-width:54vw;
  }
}
@media screen and (orientation:landscape) and (max-width: 915px)
{
  .data-chart
  {
    height: 80vh;
  }
}
@media only screen and (min-width: 320px) and (max-width: 414px) {
 .data-chart {
   height: 80vh;
 }
 .display {
  display:block;
  padding-bottom: 10px;
}
}
  @media only screen and (min-width: 768px) and (max-width: 890px) {
    .chart-header {
      display: block !important;
    }
    .button-group {
      justify-content: flex-start !important;
      padding-top: 10px;
    }
    .display {
      display:block;
      padding-bottom: 10px;
    }
    .one-month-date-range {
      > .current-range {
        padding-top:16px;
      }
    }
  }
  @media (max-width:462px) {
    canvas{
      left: 0px!important;
    }
 }
  @media (min-width:462px) {
  canvas{
    left: 12px!important;
   }
 }
  @media (max-width:575px) {
    .button-group {
      justify-content: flex-start !important;
      padding-top:10px;
  }
  .chart-header {
    width: 90%;
    margin: 8px auto;
  }
  .one-month-date-range {
    > .current-range {
      padding-top:16px;
    }
  }

  }
  @media only screen and (min-width: 321px) and (max-width: 362px) {
    .selection-size-button {
      margin-bottom: 10px ;
    }
  }
  @media (max-width:320px) {
    .selection-size-button-container {
      display: block;
    }
    .padding-5 {
      margin: 2px;
      font-size: 12px;
    }
    .chart-header {
      width: 100%;
      margin: 0 auto;
    }
  }


/* Galaxy S5 landscape for datepicker truncation*/
  @media only screen  and (max-width:824px) and (orientation: landscape){
    .mat-datepicker-popup{
      top: 0% !important;
      bottom: 0% !important ;
    }
  }

  //Footer button enabling on landscape mode
   @media only screen  and (max-width:824px) and (orientation: landscape){
    .ui-dialog-content {
     height: 167px !important ;
    }
  }
  // Chart alignment for mobile devices
  @media only screen  and (max-width:445px){
    .chart-container{
      display: contents;
    }
    .view-history-message{
      top: 0px;
    }
  }

   @media only screen  and (max-width:568px) and (orientation: landscape){
    .ui-dialog-content {
     height: 150px !important ;
    }
  }

