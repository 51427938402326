@import '../../app.scss';

app-export {
  .main {
    padding: 15px 15px 70px 15px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.3);
    background-color: $white1;
    border-radius: 8px;
    z-index: 2;
    min-width: calc(100vw - 500px);
    display: inline-block;
  }

  .loading-view-export-local {
    @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
    background: rgba(218, 205, 205, 0.5);
    z-index: 2;
    display: block;
    position: fixed;
  }

  .form {
    padding: 25px 40px;
  }

  .mat-form-field{
    margin-left: 3px;
  }

 .mat-form-field-appearance-fill .mat-form-field-flex{
    padding: 0.25em 0.25em 0 0.25em
  }

  .mat-radio-button{
    padding-top: 15px;
  }

  .custom-export-select{
    width: 80px; 
    font-size: 14px;
    height: 30px;
    width: 100%;
    box-sizing: border-box;
    mat-form-field {
      width: 80%;
      max-width: 80%;
    }
  }

  .form-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .data-settings-section {
    align-items: normal;

    .input-container:first-child {
      margin-bottom: 10px;
    }

    .input-container.datepicker {
      margin-bottom: 8px;
    }
  }

  .section-header {
    width: 190px;
    display: inline-block;
  }

  .header-text {
    font-size: 15px;
    font-weight: bold;
    color: $black1;
  }

  .data-settings-text{
    position: relative;
    top: 7px;
  }

  .input-container {
    display: flex;
    align-items: center;
  }

  .label {
    font-size: 14px;
    color: $gray1;
    display: inline-block;
  }

  .dropdown-label {
    margin-right: 15px;
    margin-top: 5px;
  }

  .radio-label {
    padding: 0 10px 0 0;
  }

  .dropdown-container {
    border: solid 1px $gray1;
    border-radius: 8px;
    padding: 3px;
  }

  .dropdown-cell{
    padding-top: 2px !important;
  }

  .select-well {
    border-radius: 8px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $gray4;
    padding: 30px 25px;
  }


  .select-well-text {
    font-size: 24px;
    opacity: 1 !important;
  }

  .button-red, .button-white {
    @include button;
    margin: 0px;
    cursor: pointer;
    display: inline-block;
  }

  .right-arrow {
    color: $gray1;
    padding: 0px 30px;
  }

  .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
      background-color: $gray1 !important;
      color: $white1 !important;
  }

  .fixed-width-input {
    text-align: center;
    width: 60px;
    border-radius: 4px;
    border: solid 1px $gray1;
    height: 32px;
    margin:8px 20px 0px 5px;
  }

  .edit-sensors-link {
    font-size: 12px;
    color: $gray1;
    margin-top: 13px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  .sensor-table {
    width: 300px;
    border-spacing: 0px;
    border-radius: 8px;
    border: solid 2px $gray4;

    .row:nth-child(odd) {
      background: $white1;
    }

    .row:nth-child(even) {
      background: $gray2;
    }

    .row {
      height: 30px;
    }

    .row :not(.row:last-child) {
      border-bottom: solid 1px $gray4;
    }

    .cell {
      overflow-wrap: break-word;
      font-size: 14px;
      color: $gray1;
      font-weight: bold;
      padding-left: 15px;
      text-align: left;
      width: 200px;
      max-width: 200px;
    }

    .entry {
      font-weight: normal;
    }

    .dropdown-small {
      width: 120px;
    }


    .entry:not(:last-child) {
      border-right: solid 1px $gray4;
    }

    .entry:last-child {
      padding-right: 1px;
    }
    .entry:nth-child(2) {
      word-break: break-word;
      -ms-word-break: break-all;
    }
    // Edge support
    @supports (-ms-ime-align:auto) {
      .entry:nth-child(2) {
        word-break: break-all;
      }
    }
    .row:nth-child(1) {
      border-width: 1px 1px 1px 1px;
      border-color: $gray1;
      border-radius: 8px 8px 0px 0px;

      td:first-child {
        border-top-left-radius: 8px;
      }

      td:last-child {
        border-top-right-radius: 8px;
      }
    }

    .row:nth-child(2) {
      td {
        border-top: 1px solid $gray4;
      }
    }

    .row:last-child {
      border-width: 0px 1px 1px 1px;
      border-color: $gray4;
      border-radius: 0px 0px 8px 8px;

      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  .sensor-table-details {
    width:100%;
    overflow: scroll;
  }

  .missing-data-input, .comments-input, .template-name {
    margin-right: 40px;
    border: 1px solid $gray1;
    border-radius: 8px;
    padding-left: 15px;

    @include common-font(
      $black1,
      UniversLTStd,
      14px,
      normal,
      normal,
      normal,
      normal
    );
  }

  .missing-data-input {
    margin-right: 10px;
    border: 1px solid $gray1;
    border-radius: 8px;
    padding-left: 5px;
    padding-right: 5px;
    width: 45%;
    height: 32px;
  }

  .comments-input {
    resize: none;
    width: 50vw;
    height: 90px;
    padding-top: 15px;
  }

  .template-name {
    height: 32px;
    width: 400px;
    padding-left: 15px;
  }

  .reset-export-buttons-container {
    float: right;
    button {
      width: 89px;
    }
  }

  .reset-button {
    margin-right: 10px;

  }

  .button-white {
    background: $white1;
    color: $red1;
    &:focus {
      background: $red1;
      color: $white1;
    }
    &:hover {
      background: $red1;
      color: $white1;
  }
    }
  .button-white:disabled {
    background: $white1;
    color: $red1;
    cursor: default;
  }

  ::-webkit-input-placeholder, ::placeholder {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    color: $gray1;
  }

  input:disabled {
    background: transparent;
    color: $gray1;
  }

  .error-message {
    position: relative;
    font-weight: bold;
    font-size: 12px;
    color: $red1;
  }

  .fixed-width-error, .missing-number-error-container {
    position: relative;
    top: 0px;
    left: 0px;
  }

  .missing-float-error, .missing-int-error {
    font-size: 12px;
    min-width: 258px;
    position:relative;
  }

  .disabled-form {
    opacity: 0.5;
  }

  .submit-button:disabled {
    background: $gray4;
    border-color: $gray4;
    pointer-events: none;
  }

  .reset-button:disabled {
    border-color: $gray4;
    color: $gray4;
  }

  .subtext {
    color: $gray1;
    font-size: 12px;
    margin-left:-15px;
  }

  .date-error {
    display: inline-block;
    min-width: 245.5px;
    margin-bottom: 8px;
  }

  .choose-date {
    margin-bottom: 10px;
    font-style: italic;
  }

  .timestamp-message {
    margin-top: 8px;
  }
  .display-flex-i {
    display: flex;
  }
  //aligned with chrome ui (start and end date)
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @media (max-width:1350px) {
    .calendar-input-container {
      display: block !important;
      }
    }
  }
  @media (max-width:767px) {
    .main {
      margin: 20px 30px;
      min-width: calc(100vw - 80px) !important;
      overflow: hidden;
    }
    .display-inline-grid {
      display: inline-grid !important;
    }
    .missing-data-input {
      width: 90%;
    }
    .select-well-text {
      font-size: 20px;
    }
    .display-inline {
      display: inline !important;
    }
    .display-flex-i {
      display: inline !important;
    }
  }
  @media (max-width:575px) {
    .main {
      margin: 0px !important;
    }
    .form {
      padding: 25px 25px !important;
    }
    .text-align-center,.div-align-center {
      width: 50%;
      margin: 0 auto;
    }

    .comments-input {
      margin-right: 0px !important;
      width: 75vw !important;
    }
    .reset-button {
      margin-bottom: 10px;
    }
    .padding-top-10 {
      padding-top: 10px
    }
    .reset-export-buttons-container {
      float: left !important;
    }
  }
  @media (max-width:420px) {
    .form {
      padding: 20px 20px !important;
    }
    .text-align-center,.div-align-center {
      width: 100% !important;
      margin: 0 auto;
    }
    .xs-display-grid {
      display: grid !important;
    }
    .fixed-width-input {
      width:100% !important;
    }
  }
  .loading-view {
    @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: block;
    position: fixed;

    .message {
      color: #fff;
    }
  }
}
