app-alert-chart {
  .data-chart {
        > div:nth-child(2) {
          white-space: pre-wrap !important;
          max-width: 60%;
          word-break: break-word; 
          -ms-word-break: break-all;
  
        }
        // Edge support
        @supports (-ms-ime-align:auto) {
        > div:nth-child(2) {
        word-break: break-all; 
      }
    }
  
      }
    }
