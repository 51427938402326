// You can add global styles to this file, and also import other style files
@import '~@angular/material/theming';
@import '~@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
// @import '~@okta/okta-signin-widget/dist/css/okta-theme.css';
@import '../src/app/containers/login/login.component.scss';
@import '../src/app/containers/schematics/schematics.component.scss';
@import '../src/app/containers/export/export.component.scss';
@import '../src/app/containers/master-page/master-page.component.scss';
@import '../src/app/components/side-nav/side-nav.component.scss';
@import '../src/app/components/terms-conditions/terms-conditions.component.scss';
@import '../src/app/components/header/header.component.scss';
@import '../src/app/containers/charts/charts.component.scss';
@import '../src/app/components/history/history-view.component.scss';
@import '../src/app/components/alert-chart/alert-chart.component.scss';
@import '../src/app/containers/charts/charts-settings/charts-settings.component.scss';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../src/app/components/export-history/export-history.component.scss';

@include mat-core();

$mat-hal-red: (
  500: #d0021b,
  contrast: (
  500: #ffffff
  )
);

$hal-primary: mat-palette($mat-hal-red, 500, 500);
$hal-accent: mat-palette($mat-red, 700);
$hal-warn: mat-palette($mat-red, 800);
$hal-theme: mat-light-theme($hal-primary, $hal-accent, $hal-warn);
@include angular-material-theme($hal-theme);
html,
body {
  max-height: 100vh;
  overflow: auto;
  // overflow-y: hidden;
  margin: 0;
  padding: 0;
  font-family: 'UniversLTStd', sans-serif !important;
  font-size: 16px;
}

@media (max-width:767px){
    html, body {
    max-height: 100%;
    overflow-x: hidden;

    }

}
html, body, app-root { margin: 0; font-family: 'UniversLTStd', sans-serif !important; }

.mat-select, .ui-widget{
  color: #13152b;
  font-family: UniversLTStd,sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
}



.mat-form-field.mat-focused .mat-form-field-label,
.mat-primary,
.mat-option.mat-selected:not(.mat-option-disabled),
.mat-select-arrow {
  color: black !important;
}

.mat-select-arrow-wrapper {
  padding-top: 5px;
}

.mat-form-field-wrapper{
  padding-bottom: 0px !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 2px 0px !important;
}

.mat-select-value{
  padding-bottom: 8px;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: black !important; 
  border-color: black !important; 
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: black !important;
}

.mat-radio-button.mat-accent .mat-radio-outer-circle {
  border-color: black !important;
}

.mat-form-field-underline {
  background-color: black !important;
}

.mat-form-field-ripple {
  background-color: black !important;
}



.popup-arrow{
  border-color: #1e1b1bdb transparent transparent transparent !important;
}

.popup-content-container{
  background-color: #472530 !important;
}

.popup-close{
  color: #faf6f6 !important;
  height: 30px !important;
}