@import '../../app.scss';

p-menubar {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
}

::ng-deep {
  .border-radius-8 {
    border-radius: 8px;
  }
  p-menubarsub {
    display: none;
  }

  .ui-menubar {
    background: $white1;
    border: none;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.25);
    height: 60px;
    padding: 0;
    position: relative;
    z-index: 1000;

    .ui-menubar-custom {
      float: none;
      height: 60px;
      padding: 0;
      width: 100%;

      .app-header-left {
        height: 60px;
        padding: 0;

        .app-logo,
        .name-content {
          padding: 0;
        }

        .name-content {
          @include white-label;
          background-color: $red1;
          max-width: 130px;
          text-transform: capitalize;
          align-items: center;
          display: flex;
          height: 100%;
          width: 100%;
        }

        .app-logo {
          height: 28px;
          padding-right: 24px;
        }
        .vertical-rule {
          border-right: solid 1px $white1;
          height: 100%;
          width: 0;
          margin-left: 0.2rem;
          margin-right: 0.2rem;
        }

        .image-container {
          background-color: $red1;
          contain: content;
          display: flex;
          height: 100%;
          justify-content: space-between;
          min-width: 280px;
          max-width: 425px;
          padding: 15px 30px;

          .header-logo {
            height: 45%;
            width: 60%;
            margin: auto;
          }
        }

        .filter-button {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;

          .edit-rule-btn {
            border-radius: 8px;
            border: 1px solid $red1;
            text-transform: uppercase;

          }
        }

      }

      .app-header-right {
        height: 60px;
        padding: 0;

        .tab-button {
          @include gray-label-right;
          border-right: 1px solid $gray4;
          cursor: pointer;
          height: 100%;
          text-align: center;

          &.active {
            @include black-label-right;
            background: $gray2;
            border-bottom: 4px solid $red1;
            text-align: center;
            cursor: default;
          }
        }

        .user-actions {
          padding: 0;
          height: 100%;

          .tab-menu {
            border-right: 1px solid $gray4;
            height: 100%;
            padding: 18px 10px 12px 0px;
            text-align: right;

            .tab-name {
              border-right: none;
              text-transform:capitalize;
            }

            .tab-icon {
              cursor: pointer;
              left: 2px;
              position: relative;
              top: 1px;
            }
          }

          .tab-notifications {
            cursor: pointer;
            height: 100%;
            padding: 15px 0;
            position: relative;
            text-align: center;

            &.highlightAlertActive {
              background-color: $gray2;
            }

            span {
              font-size: 25px;
              position: relative;
            }

            &-active {
              background: $red1;
              border-radius: 50%;
              height: 20px;
              left: 12px;
              position: absolute;
              top: -4px;
              width: 20px;
              font-size: 8px;
              padding-top: 6px;
              color: #fff;
              font-style: normal;
              font-weight: bold;
              font-family: UniversLTStd, sans-serif;
            }
          }
        }
      }
    }
  }
}

.configurations {
  background: $gray2;
  cursor: pointer;
  position: absolute;
  top: 60px;
  right: 0;
  z-index: 10;

  div {
    @include black-label-small;
    align-content: center;
    display: flex;
    flex-direction: column;
    height: 30px;
    justify-content: center;

    &:hover {
      @include black-label-center;
      background-color: $gray3;
      font-size: 14px;
    }
  }
}

.tab-menu-lss{
  padding-right: 15px !important;
}

//Rotation on ie fixes , applies to all browser
.in-progress {
  margin-left: 12px!important;
  bottom: 10px !important;
  animation: 2s linear infinite spinner;
}

.export-icon {
  margin-left: 0px;
  position: relative;
  bottom: 20px;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .ui-menubar .ui-menubar-custom .app-header-left .filter-button .edit-rule-btn {
    left:0px !important
  }
}
@media (max-width:767px) {
  .filter-button {
    width:auto !important;
    .edit-rule-btn {
      padding: 3px !important;
    }
  }
  .ui-sidebar-right {
    top: 5px !important;
  }
}
@media (max-width:575px) {
  .image-container {
   .header-logo {
        display: block;
      }
      .vertical-rule {
        display:block;
      }
  }
  .tab-menu {
    .tab-name {
      font-size:0.7em;
    }
  }
  .padding-left-20 {
    padding-left: 0px !important;
  }
  .ui-menubar .ui-menubar-custom .app-header-left .filter-button .edit-rule-btn {
    font-size: 0.7em !important;
  }
}


@media (max-width:520px) {
  .image-container  {
      .hidden {
        display: none;
      }
  }
  .width-height {
    width: 100% !important;
    height: auto !important;
  }
  .width-padding {
    min-width: 160px !important;
    padding: 0 10px !important;

  }
  @media screen and ( -ms-high-contrast:active) ,screen and (-ms-high-contrast:none){
    @media (max-width:575px) {
      .image-container {
       .header-logo {
          height:30%!important;
        }
     }
   }
  }
}

//Removed stretch on Edge
@supports (-ms-ime-align: auto) {
  @media (max-width: 520px) {
  ui-menubar {
      .ui-menubar-custom {
        .app-header-left {
          .image-container {
            display: inline !important;
            .header-logo {
              padding-top: 18% !important;
            }
          }
        }
      }
    }
  }
}
