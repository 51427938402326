// Import mixins
@import 'font-styles.mixins';

.secondary-button {
  @include secondary-button;
}

.secondary-button1 {
  @include secondary-button1;
}

.primary-button {
  @include primary-button;
}

.black-label {
  @include black-label;
}

.black-label1 {
  @include black-label1;
}

.black-label2 {
  @include black-label2;
}

.black-label-right {
  @include black-label-right;
}


.black-label3 {
  @include black-label3;
}

.black-label4 {
  @include black-label4;
}

.gray-label1 {
  @include gray-label1;
}

.black-label-small {
  @include black-label-small;
}

.gray-label-right {
  @include gray-label-right;
}

.black-label-center {
  @include black-label-center;
}

.white-label {
  @include white-label;
}

.red-label-small {
  @include red-label-small;
}
