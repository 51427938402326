@mixin button($padding: 8px 10px, $border-radius: 8px, $bg-color: #CC0000) {
  @include primary-button;
  padding: $padding;
	display: block;
	border-radius: $border-radius;
  background-color: $bg-color;
  margin: auto;
  border: 1px solid $bg-color;
  cursor: pointer;
}

@mixin button-hover($bg-color:#CC0000, $font-color: #fff) {
  background-color:$bg-color;
  color: $font-color;
}

@mixin position($position, $top:null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left : $left;
}


// Shorthand mixin for absolute position
@mixin absolute($args...) {
  @include position(absolute, $args...);
}

@mixin message($color, $font: 12px) {
  color: $color;
  font-size: $font;
  position: relative;
  font-weight: bold;
}

@mixin disabled-button {
  background-color: $gray1;
  border-color: $gray1;
  opacity: 0.5;
  cursor: default;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

@mixin no-outline {
  &:focus {
    outline: none;
  }
}

@keyframes rotation {
  from {
      -webkit-transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(359deg);
  }
}

@mixin rotation {
  animation: rotation 2s infinite linear;
}

