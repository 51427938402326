@import '../../app.scss';

app-schematics {

  .canvas-container {
    height: 100%;
    position: relative;
    text-align: center;
    width: 100%;
  }

  .dropdown-container {
    border: solid 1px $gray1;
    border-radius: 8px;
    padding: 3px;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex{
    padding: 0.25em 0.25em 0 0.25em
  }

  .schematic-wrapper {
    min-height: auto;
    text-align: center;
  }

  .data-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 50px 0 rgba(0, 0, 0, 0.3);
    min-height: calc(100vh - 125px);
    overflow: auto;
    padding: 15px;
    width: 100%;
    position: relative;
  }

  .sc-header {
    border-bottom: 1px solid #d4d8df;
    color: #13152b;
    font-family: UniversLTStd, sans-serif;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: normal;
    padding: 2px 0 24px 24px;
  }

  .selection-header {
    border-bottom: 1px solid #d4d8df;
    color: #13152b;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 10px 0 20px 24px;
  }

  .header-text {
    color: #13152b;
    font-family: UniversLTStd, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: normal;
    padding: 18px 10px 10px 24px;
  }

  .message-text {
    color: #cc0000;
    font-family: UniversLTStd, sans-serif;
    font-size: 1.5em;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    padding: 10px 10px 10px 24px;
  }

  .message-div {
    padding-top: 10px;
    text-align: center;
    width: 100%;
  }

.myCanvas {
  width: 100%;
  height: 100%;
  position: relative;
}
.loading-view {
  @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
  background: $white1;
  z-index: 2;
  display: block;
}
  .card {
    align-items: center;
  }

  h5 {
    padding-left: 10px;
    padding-right: 10px;
  }


  .legend-common {
    height: 0;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 5px;
    width: 0;
  }
  @media screen and ( -ms-high-contrast:active) ,screen and (-ms-high-contrast:none)
  {
    .data-container {
     overflow-x:hidden;
    }
  }
  @media (max-width: 665px) and (orientation:portrait) {
  .data-container{
   min-height:unset;
  }
  .loading-view{
    @include absolute($top:0, $right:-30px, $bottom: -15px, $left: 0);
  }
  }

  @media (max-width: 1225px) and (min-width: 600px) {
    .responsive-canvas {
      height: 50%;
  }
  .chart-section {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    border-radius: 8px;
    max-height: calc(100vh - 125px);
    height: 100%;
    background-color: $white1;
    box-shadow: 0 2px 50px 0 rgba($black2, 0.3);
  }
}
}
