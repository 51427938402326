@import '../../app.scss';
alert {
  .history-view-container {
    @include absolute($top:0, $right:0, $bottom: 0, $left: 0);
    background: $white1;
    z-index: 1;
    border-radius:8px;
    > div:first-child {
      @include absolute($top:50%, $left: 50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      .fa-calendar {
        color: $gray7;
        display: block;
        font-size: 137px;
        text-align: center;
        margin: 50px auto;
      }
      .btn-duration {
        @include button;
        margin: 50px auto;
        font-weight: bold;
      }
    }

  }
}

